
$icon-font-path: "../sass/bootstrap/fonts/bootstrap/";

  
$font-family-sans-serif:  'Open Sans', sans-serif;
$header-transition: all 0.4s cubic-bezier(.7, 1, .7, 1);
$font-size-base: 15px;
$line-height-base: 1.6;
$brand-primary: #218AB1;
$text-color: #656565;
$headings-font-weight: 300;
$headings-line-height: 1.2;

$carousel-control-width: 80px;
$carousel-control-width-sm: 35px;


$thin: 200;
$bold: 400;
$bolder: 600;
$boldest: 700;

$summit-complementary: #FFA422;


$navbar-height:  80px;
$navbar-logo-height:  35px;
$nav-link-padding:  10px 15px !default;


$navbar-height-shrink:  60px;
$navbar-logo-height-shrink:  30px;




$navbar-margin-bottom:  0;
$navbar-border-radius: 0;



//navbar links
// $navbar-default-link-hover-color:          #fff !default;
$navbar-default-link-hover-bg:             #f7f7f7 !default;
// $navbar-default-link-active-color:         #555 !default;


// Navbar toggle
// $navbar-default-toggle-hover-bg:           #ddd !default;
// $navbar-default-toggle-icon-bar-bg:        #888 !default;
// $navbar-default-toggle-border-color:       transparent !default;


// Navbar 
$navbar-default-color:             #777 !default;
$navbar-default-bg:                white !default;

//navbar frontpage full
$navbar-full-frontpage-color:             #fff !default;
$navbar-full-frontpage-bg:                rgba(0, 0, 0, 0.63);
$navbar-full-frontpage-hover-bg:          	lighten($navbar-full-frontpage-bg, 70%) !default;
$navbar-full-frontpage-hover-color:         #aadc1d;
$navbar-full-frontpage-border: transparent;


//font-sizes
$font-size-h1:  	27px   ;        
$font-size-h2:  	22px    ;     
$font-size-h3:   	20px;
  
// $font-size-h4:         
// $font-size-h5:         
// $font-size-h6:


$font-size-large: 	18px; 
$padding-large-horizontal: 15px;