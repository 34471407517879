.messages{
	@extend .alert !optional; 
	@extend .clearfix !optional; 
	
	&.messages--status{@extend .alert-success !optional}
	&.messages--error{@extend .alert-danger !optional}
	&.messages--warning{@extend .alert-warning !optional}
	&.warning{@extend .alert-warning !optional}
	
	.messages__icon{
		@extend .pull-left !optional
		margin-right:20px;
	}
	
	.messages__list{
		@extend .pull-left !optional
	}
	
	a{@extend .alert-link !optional}
}
