//
$nav-link-size:14px !default;



@mixin nav-links{
	font-size: $nav-link-size;
	text-transform: uppercase;
	font-weight: 400;
    letter-spacing: 1px;
	&.before-dropdown-toggle{ 	display:inline-block;	padding-right:0px;	}
	&.dropdown-toggle{ 			display:inline-block;	padding-left:5px;		}
}


header.navbar{

	
	transition: $header-transition;
	*{
		transition: $header-transition;
	}

	.header-menu{
		@extend .navbar-right;
		
		//damit hover bei allen links (dropdown) ankommt
		> li:hover > a,
		> li.active:hover > a{ background-color: $navbar-default-link-hover-bg;}
		
		//second level
		> li.dropdown {
			

			a{
				&.before-dropdown-toggle{ 	display:inline-block;	padding-right:0px;	}
				&.dropdown-toggle{ 			display:inline-block;	padding-left:5px;		}
			}
		}
	}
	
	.navbar-brand{
		padding-top: ($navbar-height - $navbar-logo-height) / 2;
		padding-bottom: ($navbar-height - $navbar-logo-height) / 2;
		transition: $header-transition;
		img{
			height: $navbar-logo-height;
			transition: $header-transition;
		}
	}
	
	.navbar-toggle {
			transition: $header-transition;
	}
	
	
	&.header-fixed-shrink{
		min-height: $navbar-height-shrink;
		@extend .border-bottom;
		box-shadow: 0px 2px 6px #ddd;
		
		.navbar-brand{
			padding-top: ($navbar-height-shrink - $navbar-logo-height-shrink) / 2 ;
			padding-bottom: ($navbar-height-shrink - $navbar-logo-height-shrink) / 2;
			height: $navbar-height-shrink;
			img{
				height: $navbar-logo-height-shrink;
			}	
		}
		
		.header-menu > li > a {
			padding-top: (($navbar-height-shrink - $line-height-computed) / 2) ;
			padding-bottom: (($navbar-height-shrink - $line-height-computed) / 2) ;
		}

		.navbar-toggle {
			margin-top: (($navbar-height-shrink - 34) / 2);
			margin-bottom: (($navbar-height-shrink - 34) / 2);
		}
	}
}






//header menu rechts
header.header{
	.header-menu-right{
		float: right;
		z-index: 999;
		margin-left: 20px;
		position: relative;
		.btn-group{
			padding-top: ($navbar-height - 30) / 2;
			padding-bottom: ($navbar-height - 30) / 2;
			transition: $header-transition;
		}
	}
	
	&.header-fixed-shrink{
		.header-menu-right .btn-group{
			padding-top: ($navbar-height - 30) / 2 - 10 ;
			padding-bottom: ($navbar-height - 30) / 2 - 10;
		}
	}
	

}
//alles unterhalb des breakpoints
@media (max-width: $grid-float-breakpoint-max) {
		li.dropdown{
			position:relative;	
			a.before-dropdown-toggle{display:block;width:100%;}
			a.dropdown-toggle{
				display:block;
				position:absolute;
				top:0; 
				right:0;
				background-color: $navbar-default-toggle-hover-bg !important;
				padding-left:20px !important;
				padding-right:20px !important;
			}
		}
			
		

		
		header.navbar{
			ul.header-menu{
				margin-top:0;
				margin-bottom:0;
				> li:first-child{
					display:block !important;
				}
				
				> li {
					border-bottom:1px solid #efefef;
					&:last-child{border: 0 none;}
				}

			}
			
			&.header-fixed-shrink{
				.header-menu > li > a {
					padding-top: 10px ;
					padding-bottom: 10px ;
				}
			}
		}
		
		//wir machen mal den header auf handies nicht sticky
		body.header-fixed header.navbar{position: relative !important;}
		body.header-fixed .main-content {margin-top: 0px !important;}
}


//alles oberhalb des breakpoints
@media (min-width: $grid-float-breakpoint-max) {
	.navbar-header{
		float:none;
		&:before,
		&:after{display:none;}
		transition: $header-transition;
	}
	
	//zweite ebene auf hover ausklappen
	li.dropdown:hover > ul.dropdown-menu{
			display:block;
	}		
}


@media (min-width: $screen-lg-min) {	
	header.navbar{
		ul.header-menu{
			font-size: 1.067em;
		}
	}
}
	
@media (max-width: $screen-sm-max) {
	header.navbar{
		ul.header-menu{
			> li > a{
				padding-left:9px;
				padding-right:9px;
			}
			
			> li:first-child{
				display:none;
			}
		}
	}
	
	//platz für navigation schaffen
	.navbar-brand img{
		height:28px !important;
		display: inline;
	}	

}


//fixed header
body.header-fixed{
	header.header{
		width: 100%;
		z-index: 100;
		top: 0;
		position:fixed;
	}
	
	.main-content{
		margin-top:$navbar-height;
	}

}


.block-menu-block {
	li.is-active-trail {
		font-weight:400;
		background-color: #FAFAFA;
	}
	
}


