.feed-icon,
.element-invisible,
.visually-hidden{
	display:none !important;
}

img {
	max-width: 100%;
    height: auto;
    width: auto;
	border: 0;
}

body{
	position:relative;
	font-weight:300;
}

table{
	@extend .table;
	// @extend .table-striped;
}

.border-left 	{border-left: 		1px solid $table-border-color;}
.border-right 	{border-right: 		1px solid $table-border-color;}
.border-bottom 	{border-bottom: 	1px solid $table-border-color;}
.border-top 	{border-top: 		1px solid $table-border-color;}
.bordered		{border: 			1px solid $table-border-color;}

.font-center{@extend .text-center;}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 0;
}