input[type="text"],
input[type="password"],
select,
textarea{

	@extend .form-control;
	@extend .input-sm;
	font-size: 15px;
}


.input-group .input-group-addon,
.input-group input[type="text"] {
	height: 34px;
	font-size: 15px;
}

.form-item--element{
	max-width:500px;
}

select,
input[type="text"],
input[type="password"] {	
	height: 34px;
}


.mrg-l-20 {margin-left: 20px;}

.form-item,
.form-actions{
	margin-bottom:	$grid-gutter-width / 2
}

.input-group{
	@extend .input-group-sm;
}

.btn-group{
	@extend .btn-group-sm;
}

input[type="submit"]{	
	@extend .btn;
	// @extend .btn-sm;
	@extend .btn-default;

}

.dropdown-menu-right {
	margin: 0;
}



input.btn-danger[type="submit"]	{ 		@extend .btn-danger;}
input.btn-link[type="submit"]	{ 		@extend .btn-link;}
input.btn-success[type="submit"]{ 		@extend .btn-success;}
input.btn-primary[type="submit"]{ 		@extend .btn-primary;}
input.btn-info[type="submit"]	{ 		@extend .btn-info;}
input.btn-warning[type="submit"]{ 		@extend .btn-warning;}

input.btn-xs[type="submit"]{ 		@extend .btn-xs;}
input.btn-sm[type="submit"]{ 		@extend .btn-sm;}
input.btn-lg[type="submit"]{ 		@extend .btn-lg;}



//autocomplete


html.js input.form-autocomplete {
    background-image: url(../../../../../../misc/throbber-inactive.png);
    background-position: 100% center;
    background-repeat: no-repeat;
}


html.js input.throbbing {
  background-image: url(../../../../../../misc/throbber-active.gif);
  background-position: 100% center; /* LTR */
  
}

#autocomplete {
    overflow: hidden;
    position: absolute;
    z-index: 100;
	
	border: 1px solid $gray-lighter;
	background-color: #fff;
	border-radius: 0px;
	box-shadow: 0px 0px 0px 1px green;
	box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

#autocomplete ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

#autocomplete li {
    background: #fff;
    color: $gray;
    cursor: default;
    white-space: pre;
    zoom: 1;
	padding:$padding-small-vertical $padding-small-horizontal ;
	border-top:1px solid $gray-lighter;
	&:first-child{
		border-top:0 none;
	}
	&:hover{
		color: $gray-lighter;
		background-color: $brand-primary;
		cursor: pointer;
	}
}






.form-type-checkboxes{
	.form-item{margin:0;}
}

.form-required{
	@extend .text-danger;
}




/*
	User Registration und LOGIN
*/

//body.page-customer-register{

	.main-tabs{display:none;}

	#content{@extend .row;}
	
	#user-login,
	.user-login-infoblock,
	#user-register-form,
	.user-reg-infoblock{
		@extend .col-md-6;
	}
	
	#user-login,
	#user-register-form{
		@extend .border-right;
	}
	
//}


// search form
#block-search-form {

	@extend .form-inline;

	.form-item {
		@extend .form-group;
		
		input[type="text"] {
			width: 185px;
		}
	}
	
	.form-actions{
		@extend .form-group;
		
		.form-submit {
			@extend .btn-primary;
		}		
	}
}


// search form output
#search-form {

	@extend .form-inline;

	.form-item {
		@extend .form-group;
	}
	
	.form-submit {
		@extend .btn-primary;
		@extend .form-group;
		margin-top: 25px;
	}		
}

body.page-search {

	h2 {
		margin-top:30px;
		margin-bottom: 20px;
	}
	
	h3 {
		font-weight:400;
		margin-bottom: 6px;
		margin-top: 10px;
	}
	
	ol,
	
	.search-snippet-info {
		padding-left: 0;
		
		.search-snippet {line-height:l.2;}
		.search-info {display:none;}
	}
}


//oberhalb des floatpointes die abstände groesser
@media (min-width: $screen-md-min) {

	#user-login,
	#user-register-form {padding-right: 50px;}
	
	.user-login-infoblock,{
		padding-left: 50px;
	}
	
	.user-reg-infoblock{
		padding-left: 50px;
		padding-top: 25px;
	}
	
	.form-item{
	
		.form-item--label{
			padding-left: 5px;
			padding-right: 5px;

		}
	}
		
} 


@media (max-width: $screen-md-max) {
	
	#user-login,
	#user-register-form {padding-right: 35px;}
	
	.user-login-infoblock,
	.user-reg-infoblock{
		padding-left: 35px;
	}

	#block-search-form {
		.form-item {
			input[type="text"] { width: 140px;}
		}
	}
}


//alles unterhalb des breakpoints
@media (max-width: $screen-md-min) {
		
	#user-login,
	#user-register-form{
		border-right:0px;
		padding-right: 5px;
		padding-left: 5px;
		
		
	}

	.user-login-infoblock,
	.user-reg-infoblock{

		float:left;
		padding-top: 50px;
		padding-left: 5px;
		margin-top:10px;
		border-top: 1px solid $table-border-color;
		
		ul ol{
			padding: 0;
		}
	}
	
	.form-actions { padding-left: 0;}
	
}


/*User registration*/
 
 
@mixin no-fieldsets(){
	fieldset{
		border:0 none;
		padding:0;
		margin:0;
	}
 }
 


 
.form-item:not(.form-type-checkbox){
	// @include horizontal-forms(normal); 

}
.form-item.form-type-checkbox{
	// @include horizontal-forms(checkbox);
}




@mixin horizontal-form(){

	@extend .form-horizontal;
	.form-item{
		@extend .content-xs;
		@extend .no-bottom-space;
		@extend .form-group;
		
		.form-item--label{
			@extend .col-sm-4;
			@extend .control-label;
			padding-top:0;
			line-height: 1.1;
			white-space:nowrap;
			font-size: 14px;

		}
		
		.form-item--element{ 
			@extend .col-sm-8;
		}
	}
	
	.form-item.form-type-checkbox{
		.form-item--element{
			@extend .col-sm-push-4;
		}
		.form-item--label{
			width:auto;
			float:none;
			padding:0 10px 0 0;
		}
	}
	
	input[type="submit"]{
		// @extend .col-sm-9;
		// @extend .col-sm-push-3;
	}
	
	#edit-actions{
		@extend .content;
		@extend .col-sm-8;
		@extend .col-sm-push-4;
	}
}
 
#user-register-form{
	@include horizontal-form();
	@include no-fieldsets();
}
 
 
 

//affiliate form
 
 #afiliate-link-node-form{
	// @include horizontal-form();
	@include no-fieldsets();
	
	.group-afiliate-discount{
		// @extend .well;
		// @extend .row;
		
		legend{
			// @extend .row;
		}
		.fieldset-wrapper{
			@extend .well;
			@extend .col-md-6;
			@extend .col-md-push-3;
		}
	}
}


// rechnungsdaten
#user-profile-form{
	@include horizontal-form();

}

 
 
@media (max-width: $screen-md-min) {
  select,
  textarea,
  input[type="text"] {
//		font-size: $font-size-large !important;
//		height: $input-height-large !important;
//		line-height: $input-height-large !important;
  }
}



// backend account form

body.page-user-edit {
	.group-customer-bank-transfer{
		margin-top: 30px;
	}
	
	.form-actions a {
		padding-left:15px;
	}
	
}




