  /*Pricing*/
  
table.pricing-table{
	
	$border: 1px solid #ddd;
	$border-dot: 1px dotted #ddd;
	
	tr > td {
		line-height:1.3;
		vertical-align: middle;
	}
	
	td.basic,
	td.professional{
		width:300px;
		text-align:center;
		border: $border;
		font-weight:600;
		.small,
		small{
			font-weight:400;
		}
	}
	
	td.basic-professional{
		text-align:center;
		border: $border;
	}
	
	td.feature-list{
		border-top: 0 none;
		border-bottom: $border-dot;
		width:300px;
		text-align:right;
	}
	
	tr.top{
		
		td.feature-list{
			border:0 none;
		}
		td.basic{
			background-color:rgba(33, 138, 177, 0.1);
			border-color:$brand-primary;
			color:$brand-primary;
		}
		
		td.professional{
			background-color:$brand-primary;
			border-color:$brand-primary;
			color:white;
		}
	}
	
	tr.head{
		td.feature-list{
			border:0 none !important;
		}
	}
	
	tr.no-border td{border:0 none !important;}
	
	tr.price-row td.feature-list {
		// border:0 none;
	}
	
	tr.buttons td{
		padding-top:20px;
		padding-bottom:20px;
		
		.btn{
			@extend .btn-block;
			font-weight: 700;
			// font-size:1.3em;
			small,
			.small{
				font-weight:400;
			}
		}
		padding-left: 15px;
		padding-right: 15px;
	}

	h2{
		font-weight:700;
	}
	
	.price{
		font-size: 35px;
		font-weight:700;
		color: $brand-primary;
		margin: 0 10px;
	}
	.price-sub{
		color: $brand-primary;
	}
	
	
	.btn{
		white-space: normal;
	}
	
	//icons
	i.fa-info-circle {color: $brand-primary;}
	i.fa-check {color: $brand-success; @extend .fa-2x;}
	i.fa-times {color: $brand-danger; @extend .fa-2x;}


	
	
	
	
	
}


@media (max-width: $grid-float-breakpoint-max) {
	table.pricing-table{
		// td{padding:5px;}
		// td.basic{display:none;}
		td.basic{display:none;}
		#price-switches{display:block !important;}
	
		td.basic,
		td.professional{
			width:200px;
		}
		td.feature-list{
			width:160px
		}
	
		tr.buttons td{
			padding:8px;
			.btn{
				padding: $padding-small-vertical $padding-small-horizontal / 2;
				// font-size: $font-size-small;
				line-height: $line-height-small;
				border-radius:$btn-border-radius-small;
			}
		}

	}
}

@media (min-width: $grid-float-breakpoint-max) {
	table.pricing-table{
		td.professional,
		td.basic{display:table-cell !important;}
		#price-switches{display:none;}
	}
	
}


@media (max-width: 400px) {
	#price-switches{
		.btn{
			border-radius:			$btn-border-radius-small;
			width:100%;
		}
		.btn:first-child{
			margin-bottom: 3px;
		}
		.btn:last-child{}
	}
	
}


// {}





