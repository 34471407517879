body.page-tour.page-views{

	.main-container{
		
		//die grid klassen der normalen conainer->row->col  neutralisieren
		width: auto;
		padding-left: 0;
		padding-right: 0;
	
	
		> .row{
			margin-left: 0;
			margin-right: 0;
			
			> .col-lg-12{
				padding :0;
			}
		}
		
		

		
	
		.fullwidth-inner{
			@extend .container;
			padding :0;
		}
		
		

		
		
		.views-row{
			@extend .content;
			
			.tour-row{
				@extend .row;
			}
			
			.tour-body	{
				@extend .col-md-6;
			}
			
			.tour-picture	{
				@extend .col-md-6;
				
				img{
					@extend .border;
					@extend .img-rounded;
				}
			}
			
			&:first-child{
				@extend .no-top-space;
			}
		}
		
		.views-row.views-row-even{
			background-color:#efefef;
			@extend .border;
			.tour-body	{		@extend .col-md-push-6;}
			.tour-picture	{	@extend .col-md-pull-6;}
		}
		
		

	}




}


