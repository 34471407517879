
//font
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';




//import bootstrap
@import 'variables';

//import bootstrap
@import 'bootstrap/scss/bootstrap';

//import font awesome
@import 'font-awesome/scss/font-awesome';



//ovveride default drupal styles
@import 'init';


//import custom components
@import 'custom-components/custom-components';



