nav.breadcrumb{
	h2{display:none;}
}

.description {
	@extend .small;
	@extend .text-muted;
}

.title-wrapper{
    @extend .bg-primary;
    padding: 15px 0;
	margin-bottom:20px;
	
	.page-title{
		margin:0;
	}
}

.container.main-container{
	@extend .content;
}


.main-tabs{
	@extend .content-xs;
	@extend .no-top-space;
}


//symbole in ueberschriften
h1,h2,h3,h4{
	i{
		margin-right:0.5em;
	}
}

.fullwidth-wrapper{
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    background-color: whitesmoke;
}

.border{
	border: 1px solid #dddddd;
}


.border-top{
	border-top: 1px solid #dddddd;
}

.border-bottom{
	border-bottom: 1px solid #dddddd;
}


//border bei sidebar weg, wenn breakpoint
@media (max-width: $screen-md-min) {
	body.sidebar-second{
		main{
			border-right:none;
		}
	}
}

//border bei sidebar
body.sidebar-second{
	main{
		@extend .border-right;
	}
}







//blog uebersichten
body.page-taxonomy.page-views,
body.page-faq.page-views{
	article{
		@extend .content;
		@extend .border-bottom;
	}
	.views-row:first-child article{@extend .no-top-space;}
	.views-row:last-child article{border: 0 none;}
	
	.blog-head h3{ 
		@extend .no-margin;
	}
	
	.blog-head{
		@extend .content-xs;
		@extend .no-top-space;
	}
}



.call-to-action-block.call-to-action-tour{
	@extend .text-center;
	@extend .content-xs;
	
	.btn-wrapper{@extend .content-xs;}
}



.icon-rows{
	.icon-row{
		@extend .clearfix;
		.icon{
			float:left;
			width: 20px;
		}
		.value{
			float:left;
		}
	}
}




.link-all			{		font-size:0.75em; color: $gray-dark; }
.link-all:hover		{	 	color: $gray-darker; }




/*activation page after Mail*/ 

body.page-activate-mail{

	#content{min-height:400px;}
	.activation-timer h3 {display:inline-block; margin:10px 0; line-height:1em; width:325px;}
	.step-activate span{display:none;}
	.step-activate.done span{display:inline-block;color:$brand-success; margin:10px 0;}
	.step-activate{margin-top:14px;}


	.step-activate:not(.done) i.fa-check {display:none;}
	.step-activate.done i.fa-spin {display:none;}
	
	.fa-check{color:$brand-success;}
	#my-timer{margin-top:1em;}
}



//kontaktseite
body.page-contact{
	main{
		// @extend .row;
		form#contact-site-form{
			@extend .col-sm-7;
			@extend .border-right;
//			@extend .space-md-hor;
		}
		.block.contact-adress-block{
			@extend .col-sm-5;
//			@extend .space-md-hor;
		}
	}

}

//border bei sidebar weg, wenn breakpoint
@media (max-width: $screen-sm-min) {
	body.page-contact{
		main{
			form#contact-site-form{
				border-right:none;
				margin-bottom:$grid-gutter-width;
				padding-bottom:$grid-gutter-width;
				border-bottom: 	1px solid $table-border-color;
			}
		}
	}
	
	/* Silbentrennung auf kleinen Bildschirmen */
	body { 
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}
	
	
}




//seitenleiste
/*Custom fontawesomes*/
.block-glyph-title h2.block__title:before {
  font-family: FontAwesome;
  margin-right: 10px; }

.block-glyph-title.blog h2.block__title:before {
  content: "\f040"; }

.block-glyph-title.tags h2.block__title:before {
  content: "\f02c"; }

.block-glyph-title.faq h2.block__title:before {
  content: "\f128"; }

.block-glyph-title.user h2.block__title:before {
  content: "\f007"; }

.block-glyph-title.fighter h2.block__title:before {
  content: "\f0fb"; }

.block-glyph-title.star h2.block__title:before {
  content: "\f005"; }

.block-glyph-title.sign-in h2.block__title:before {
  content: "\f090"; }
  
.block-glyph-title.search h2.block__title:before {
  content: "\f002"; }  
  
.block-glyph-title.title-small h2.block__title {
  font-size: 1.067em; 
  font-weight: 400;
  }   


ul.fontawesome {
  list-style: none inside none;
  padding-right: 1.5em; }
  ul.fontawesome li {
    margin-bottom: 25px;
    position: relative; }
  ul.fontawesome li:before {
    font-family: FontAwesome;
    font-size: 1.5em;
    left: -1.5em;
    position: absolute; }
  ul.fontawesome li.check:before {
    content: "\f00c";
    color: #078501; }
  ul.fontawesome li.phone:before {
    content: "\f095";
    color: #218ab1; }
  ul.fontawesome li.info-circle:before {
    content: "\f05a";
    color: #218ab1; }


//bloecke in sidebar mit abstaenden
@media (min-width: $screen-md-min) {

	aside.sidebar-second .block {
		margin-bottom: $grid-gutter-width;
	}
}	

	
aside.sidebar-second .block ul.menu {
	list-style:none;
	margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
	
	li a {
		padding: 5px;
		display: block;

		&:hover{
			color: $brand-primary;
			background-color: whitesmoke;
			text-decoration: none;

		}
	}
}


/*activation page after Mail*/
body.page-activate-mail #content {
  min-height: 400px; }
body.page-activate-mail .activation-timer h3 {
  display: inline-block;
  margin: 10px 0;
  line-height: 1em;
  width: 325px; }
body.page-activate-mail .step-activate span {
  display: none; }
body.page-activate-mail .step-activate.done span {
  display: inline-block;
  color: #078501;
  margin: 10px 0; }
body.page-activate-mail .step-activate {
  margin-top: 14px; }
body.page-activate-mail .step-activate:not(.done) i.fa-check {
  display: none; }
body.page-activate-mail .step-activate.done i.fa-spin {
  display: none; }
body.page-activate-mail .fa-check {
  color: #078501; }
body.page-activate-mail #my-timer {
  margin-top: 1em; }


  
footer.footer .view-blog{

	.link-all{
		font-weight:normal;
		@extend small;
	}
	.views-row{
		margin-bottom: 8px;
		padding-bottom: 8px;
		border-bottom: 1px solid $gray-light;
		
		&:last-child{
			border: 0 none;
		}
	}
	
	h3{
		font-size: $font-size-base;
		margin: 0; 
	}
	.blog-meta{
		@extend .no-top-space;
		a{
			font-weight:400;
		}
	}
 
}


.p-t-10 {padding-top:10px;}
.p-t-20 {padding-top:20px;}
.p-t-30 {padding-top:30px;}
.p-t-50 {padding-top:50px;}
.p-b-10 {padding-bottom:10px;}
.p-b-20 {padding-bottom:20px;}
.p-b-30 {padding-bottom:30px;}
.p-b-50 {padding-bottom:50px;}

.mrg-t-10 {margin-top:10px;}
.mrg-t-20 {margin-top:20px;}
.mrg-t-30 {margin-top:30px;}
.mrg-t-50 {margin-top:50px;}
.mrg-b-10 {margin-bottom:10px;}
.mrg-b-20 {margin-bottom:20px;}
.mrg-b-30 {margin-bottom:30px;}
.mrg-b-50 {margin-bottom:50px;}

.mrg-l-10 {margin-left:10px;} 
.mrg-l-20 {margin-left:20px;} 
.mrg-l-30 {margin-left:30px;} 
.mrg-r-10 {margin-right:10px;}
.mrg-r-20 {margin-right:20px;}
.mrg-r-30 {margin-right:30px;}


/* ckeditor styles*/
.rteindent1 {
    margin-left: 40px;
}
.rteindent2 {
    margin-left: 80px;
}
.rteindent3 {
    margin-left: 120px;
}
.rteindent4 {
    margin-left: 160px;
}
.rteleft {
    text-align: left;
}
.rteright {
    text-align: right;
}
.rtecenter {
    text-align: center;
}
.rtejustify {
    text-align: justify;
}
.ibimage_left {
    float: left;
}
.ibimage_right {
    float: right;
}

/* Silbentrennung */
.hyphen { 
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

.blog-body {

	li {padding-top: 10px;}

}