ul.tabs{
@extend .clearfix;
@extend .nav;
@extend .nav-pills;
	li.is-active{
		@extend .active;
	}
	
margin-bottom: -1px;
z-index: 1;
position: relative;	

}