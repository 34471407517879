
$highlight: #212121;

.summit-bs-slider{
	.item{
		height: 260px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		
		@extend .content;
		@extend .hyphen;
		
		padding-top: 30px;
		padding-bottom: 40px;
		padding-left: $carousel-control-width +15;
		padding-right: $carousel-control-width +15;
		
		h2{
			color:white;
			font-size:2em;
			margin-bottom: 0.8em;
			line-height: 1.4;
			font-weight:600;
		}

		p{
			color: white;
			font-size:1.2em;			
		}
	}
	
	.carousel-control {background-image:none;}
	
	.btn{margin-top:20px;}
}


@media (max-width: $screen-lg-min) {
	.summit-bs-slider{
		
		.item{

			padding-left: $carousel-control-width;
			padding-right: $carousel-control-width;
				
			h2 {font-size:1.733em;}
			
			p {font-size:1.067em;}
		}
	}
}

@media (max-width: $screen-md-min) { 
	.summit-bs-slider{
		
		.carousel-control {width: $carousel-control-width-sm;}
		
		.item{
			
			padding-left: $carousel-control-width-sm +15;
			padding-right: $carousel-control-width-sm +15;
		
			h2 {font-weight:400;}
		}
	}
}

@media (max-width: $screen-sm-min) { 
	.summit-bs-slider{
		
		.item{
			
			padding-top: 20px;
			padding-left: $carousel-control-width-sm +10;
			padding-right: $carousel-control-width-sm +10;
		
			h2 {font-size:1.333em;}
			
			p{font-size:1em;}
		}
	}
}

@media (max-width: 600px) { 
	.summit-bs-slider {.item {height: 300px;}}
}

//
@media (max-width: 560px) { 
	.summit-bs-slider {.item {height: 360px;}}
}

@media (max-width: 400px) { 
	.summit-bs-slider {.item {height: 420px;}}
}

@media (max-width: 340px) { 
	.summit-bs-slider {.item {height: 480px;}}
}


