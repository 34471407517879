.page-user .main-tabs,
.node-type-afiliate-link .main-tabs,
.page-user .profile h3 {
    display: none;
}

.group-afiliate-discount div.fieldset-wrapper {left:0 !important;}


.profile{
	margin:0;
	
	.profile-buttons{
		margin-bottom:$grid-gutter-width;
	}
	.profile-panel{
		@extend .well;
		margin-bottom:$grid-gutter-width;
	}

	.afiliate-links .view .view-header{
		margin-bottom:$grid-gutter-width;
	}

}


.field-label-inline .field-label,
 .field-label-inline .field-items {
    float: left;
}

.page-user .profile-panel .field-label {
    width: 150px;
}