

#frontpage-panel{

	.frontpage-panel-section.panel-2nd,
	.frontpage-panel-section.panel-3rd,
	.frontpage-panel-section.panel-4th,
	.frontpage-panel-section.panel-5th,
	.frontpage-panel-section.panel-6th,
	.frontpage-panel-section.panel-7th{
		
		margin-top:30px;
		margin-bottom:30px;
		
		.wrapper{
			@extend .container;
			.panel-pane{@extend .content}
		}
		
		@media (max-width: $grid-float-breakpoint) {
			margin-top:10px;
			margin-bottom:10px;	
		}
	}
	
	.frontpage-panel-section.panel-4th .wrapper {
	
		border-top: 1px solid #dddddd;
	
	}
 	

	//bilder auf der frontpage haben einen rahmen
	img {
		@extend .border;
	}


	//bilder im slider haben keinen rahmen
	.frontpage-panel-section.panel-1st{
		img{ border:0 none;}
	}

	#summit-frontpage-descr-large{
		h1{
			font-size: 1.8em;
			@extend .no-margin;
			@extend .margin-bottom-40;
			@extend .text-center;
			
		}
		
		.button-wrapper{
			@extend .text-center;
			@extend .content-xs;
		}
	}

	.frontpage-panel-section.panel-2nd,
	.frontpage-panel-section.panel-5th{
		@extend .fullwidth-wrapper;
	}

	.call-to-action{
		display:table;
		h1{
			margin-top:0;
			font-size:1.8em;
		}
		> div {
			display:table-cell;
			vertical-align:top;
			padding:0 10px;
			
			&:first-child{padding-left:0;}
			&:last-child{padding-right:0;}
		}
				
		.call-to-action--icon {@extend .text-success;	}
		.call-to-action--button{vertical-align:middle;}
	}

	//responsive
	//alles unterhalb des breakpoints
	@media (max-width: $grid-float-breakpoint) {
		.call-to-action{
			display:block;
			text-align:center;
			> div {
				display:block;
			}
			
			h1 { font-size:1.4em; }
			
		}
		#summit-frontpage-descr-large { h1 { font-size: 1.6em; }}
		
		
		
	}


	//blog bereich
	.pane-block[class*="pane-views-blog"]{
		.blog-head h3 {
			font-size: 18px;
			margin: 0;
			line-height: 1.3em;
		}

		.blog-head .blog-meta {
			@extend small;

		}

		.block-frontpage-row {
			@extend .content-xs;
			@extend .border-bottom;
			&:last-child{ border:0 none;}
		}
		
		a.link-all{
			@extend .text-muted;
			@extend small;
		}
	}








}



	.frontpage-panel-section.panel-6th{
		.row-4-pane{
			@extend .row;
			.image{
				@extend .col-xs-6;
				@extend .col-sm-12;
			}
				
			.text{
				@extend .col-xs-6;
				@extend .col-sm-12;
			}
		}
	}
	
	@media (max-width: $screen-xs-min) { 
		.frontpage-panel-section.panel-6th{
			.row-4-pane{
				.image{display:none;}	
				.text{width:100%;}	
			}
		}
		
		
		
	}

	@media (min-width: $screen-sm) { 
		.frontpage-panel-section.panel-6th{
			.row-4-pane{
				.image{margin-bottom:$line-height-computed;}	
			}
		}
	}

 /*Sytling Quicktabs*/
 
 .quicktabs-wrapper.quicktabs-style-nostyle{
 
	/*item list*/
	$link-padding: 5px;
	$border-height: 4px;
	$li-padding: $link-padding ; /*no change*/
	
	.item-list{
		a{}
		
		li {display:inline-block;padding: 0 0 $li-padding 0;margin:0;}
		
		li a{	
			padding: $link-padding 20px $link-padding 20px;
			background-color: $gray-lighter;
		}
		
		ul{
			 margin: 0 0 0.5em;
			 padding:0;
			border-bottom: $border-height solid $gray-lighter;
			line-height:normal;
		}
		
				
		/*hovered, active elements*/
		a:hover,
		a:focus{
			text-decoration:none;
		}
		li.active a,
		li.active:hover a { background-color: $brand-primary; color: $gray-lighter; border-bottom: $border-height solid $brand-primary;  }
		li:hover a { color: $gray-lighter; background-color: $gray-light; border-bottom: $border-height solid $brand-primary;}
	}

 }
 
 
// md-slider
.md-slide-item {
	.slider-background-img{
		z-index: -1 !important;
	}
}
