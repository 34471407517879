#logo {
  padding: 10px;
  img{max-height:100%;}
}



// login block im header

header.header{
	.login-form-block{
		.dropdown-menu{
			padding-left:15px;
			padding-right:15px;
			min-width:320px;
		}
	}
}

//alles unterhalb des breakpoints
@media (max-width: $grid-float-breakpoint) {
	.login-form-block{
		padding-left:10px;
		padding-right:10px;
		
	}
}

//auf ganz kleinen screens
@media (max-width: $screen-xs-min) {

	.header.header{
		.login-form-block{
			> .btn:first-child{display:none;}
			> .btn:nth-child(2){border-radius:$btn-border-radius-small;}
			.dropdown-menu{
				width: 100%;
				position: fixed;
				z-index: 10;
				border-radius: 0;
				border-width: 1px 0 1px 0;
				margin:0;
			}
		}
		
		.header-menu-right{margin-left:0;}

		.navbar-brand {display:table-cell; padding-right:0;}
		.navbar-brand img{
			height:25px !important;
			display: inline;
		}
	}
	
	.header.header{
		.dropdown-menu{
			top:$navbar-height;
		}
	}
	.header.header.header-fixed-shrink{
		.dropdown-menu{
			top:$navbar-height-shrink;
		}
	}
}
