.footer.region{
    background-color: #4a4a4a;
	color: #c2c2c2;
	@extend .content-xs;
	@extend .no-top-space;

	a{
		color: whitesmoke;
		font-weight: 600;
	}
	
	.icon,
	strong{
		color: whitesmoke;
	}
	
	
	//mini panel
	#mini-panel-footer{
		.panel-flexible-inside{
			@extend .row;
			
			.panels-flexible-region{
				@extend .content-xs;
			}
			
			
			.panels-flexible-region:first-child{
				@extend .col-lg-3; 
				@extend .visible-lg-block; 

			}
			.panels-flexible-region:nth-child(2){
				@extend .col-lg-3; 
				@extend .col-sm-6;
				@extend .visible-sm-block;
				@extend .visible-md-block; 
				@extend .visible-lg-block; 
				
			}
			.panels-flexible-region:nth-child(3){
				@extend .col-lg-6; 
				@extend .col-sm-6;
				@extend .col-xs-12;
			}
			
			.panels-flexible-region-inside{
				padding:0;
			}
			
			font-size:0.933em;
		}
	}
	
	
}



.footer-bottom.region{
	color: #c2c2c2;
	background-color: #373737;
    @extend small;
    @extend .content-xs;
    // padding: 10px 0;

	a{
		font-weight: 600;
		color:inherit;
	}




}

//fixed footer
// see: https://css-tricks.com/couple-takes-sticky-footer/
html {
  height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.main-content {
  flex: 1;
}

