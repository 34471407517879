#cookie-compliance{
	position:fixed;
	bottom:0;
	z-index:10000;
	 width:100%;
	 
	.cookie-compliance__inner{
		@extend .container;
	}

}

