
.blog-meta{
	@extend small;
	padding-top:5px;
	margin-top:5px;
	div{
		@extend .pull-left;
		margin-right: 5px;
	}
}


.blog-slider .field{
	@extend .content-xs;
	@extend .no-top-space;
		
	.field-slideshow-wrapper{position:relative;}
	.field-slideshow-controls{
		a{
			position: absolute;
			top: 50%;
			z-index: 10;
			font-size: 0;
		}
		
		
		
		a.prev{left: 10px;}
		a.next{right: 10px;}
		
		
		a.prev:before{
			content: "\f104";
		}
		
		a.next:before{
			content: "\f105";
		}
		
		a:before{
			background-color: rgba(0, 0, 0, 0.33);
			color: whitesmoke;
			display: inline-block;
			font-family: FontAwesome;
			font-size: 60px;
			line-height: 50px;
			margin-right: 10px;
			text-align: center;
			width: 50px;
		}
	}

}